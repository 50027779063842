import React, { useEffect, useState, useContext } from "react"
import { ROUTE_PATHS } from "../utils/routes"
import { Link } from "react-router-dom"
import { ErrorMessage } from "./styled"
import { OfferApi } from "../utils/api"
import { AppContext } from "./context/AppContext"
import PropTypes from "prop-types"
import AnalyticsService from "../utils/AnalyticsService"

export const FrontDoorOffersSection = ({ offerList, history, transactionChannel, inputPhoneNumber, isTfbOrBau }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { dispatch, state } = useContext(AppContext);
    const switch2Url = process.env.REACT_APP_SWITCH2_URL;
    const samsclub = 240003
    const costco = 240150

    const keepAndSwithOffer = offerList.map(offer => offer.offerId).indexOf(111692) > -1 ? offerList.filter(offer => offer.offerId === 111692)[0] : null;
    const cfOffer = offerList.map(offer => offer.offerId).indexOf(111694) > -1 ? offerList.filter(offer => offer.offerId === 111694)[0] : null;
    const hintOffer = offerList.map(offer => offer.offerId).indexOf(268636) > -1 ? offerList.filter(offer => offer.offerId === 268636)[0] : null
    const promoOffers = offerList.filter(offer => offer.offerId !== 111692 && offer.offerId !== 111694 && offer.offerId !== 268636);

    useEffect(() => {
        setIsLoading(false);
    }, [])

    const setCampaign = async (promoCode) => {
        try {
            dispatch({ type: "SETPROMOCODE", payload: promoCode });
            dispatch({ type: "SETSELECTEDPHONE", payload: inputPhoneNumber });
            let lookupValues = {
                phoneNumber: inputPhoneNumber,
                promoCode: promoCode,
                validateLine: true,
                userInfo: state.user.userInfo
            };
            dispatch({ type: "SETLOOKUPVALUES", payload: lookupValues });
            AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: promoCode, action: isTfbOrBau, label: "link_offer" })
            await OfferApi.getOfferDetails(lookupValues).then((response) => {
                if (!response.offerId && response.ineligibleReason) {
                    setErrorMessage(response.ineligibleReason);
                    setIsSubmitting(false);
                }
                else {
                    dispatch({ type: "SETCAMPAIGN", payload: response });
                    history.push(ROUTE_PATHS.PROMOTION);
                }
            },
                (error) => {
                    setErrorMessage("Something went wrong, please try again");
                    setIsSubmitting(false);
                })
        }
        catch (error) {
            setErrorMessage("Something went wrong, please try again");
            setIsSubmitting(false);
        }
    }

    return (
        !isLoading &&
        <>
            <div className="offerSection">
                <h3 className="font-weight-bold">Congratulations! Here are the promotions you <u>may</u> qualify for. <strong className="magenta">Please select one to continue: </strong></h3>
                <p >Remember, you can only select <span className="magenta font-weight-bold"> one offer per line </span> on your account</p>
                {keepAndSwithOffer && <div>
                    <a href={switch2Url + "KS-" + keepAndSwithOffer.offerId + "-" + inputPhoneNumber}>{keepAndSwithOffer.description}</a>
                </div>}
                {cfOffer && <div>
                    <a href={switch2Url + "CarrierFreedom-" + cfOffer.offerId + "-" + inputPhoneNumber}>{cfOffer.description}</a>
                </div>}
                {hintOffer && <div>
                    <a href={switch2Url + "HintFreedom-" + hintOffer.offerId + "-" + inputPhoneNumber}>{hintOffer.description}</a>
                </div>} 
                {promoOffers.length > 0 && transactionChannel === "4" &&
                    <div>
                        <p>Great News! You also qualify for the Costco Member Benefit promotion in addition to one of these great offers. Visit <Link  to="#0" onClick={() => setCampaign(costco)} key={costco}>promotions.t-mobile.com/costco</Link> to submit for this offer.</p>
                    </div>
                }
                {promoOffers.length > 0 && transactionChannel === "5" &&
                    <div>
                        <p>Great News! You also qualify for the Sam’s Club Member Benefit promotion in addition to one of these great offers. Visit <Link  to="#0" onClick={() => setCampaign(samsclub)} key={samsclub}>promotions.t-mobile.com/samsclub</Link> to submit for this offer.</p>
                    </div>
                }
                {promoOffers.length > 0 &&  promoOffers.map((offer) =><div >
                <Link  to="#0" onClick={() => setCampaign(offer.promoCode)} key={offer.offerId}>{offer.description}</Link>
                </div>)}
            </div>
            {!isSubmitting && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        </>
    )
}

FrontDoorOffersSection.propTypes = {
    offerList: PropTypes.array,
    history: PropTypes.object.isRequired,
    transactionChannel: PropTypes.string,
    inputPhoneNumber: PropTypes.string.isRequired,
    isTfbOrBau: PropTypes.bool
}