import React, { useReducer, createContext, useEffect} from "react"
import PropTypes from "prop-types"

export const STATE_NAME = "TMO_PROMO_STATE"

const initialState = {
  user: {phoneNumbers: []},
  selectedPhone: "",
  campaign: {},
  iSubmission: {},
  files: [],
  submissions: [],
  selectedSubmission: {},
  faq: [],
  isSubmitted: false,
  choseMailIn: false,
  commonError: "",
  lookupValues: {},
  miscError: {},
  aaaMemberId: null,
  costcoMemberId: "",
  samsMemberNumber: "",
  validateLine: true,
  promoCode: ""
}

const persistedState = JSON.parse(sessionStorage.getItem(STATE_NAME))

const finalInitialState = {...initialState, ...persistedState}

const appReducer = (state, action) => {
  switch (action.type) {
  case "SETISUBMISSION":
    return {...state, iSubmission: action.payload }
  case "SETFILES":
    return {...state, files: action.payload}
  case "SETUSER" :
    return {...state, user: action.payload}
  case "SETSUBMISSIONS":
    return {...state, submissions: action.payload}
  case "SETCAMPAIGN":
    return {...state, campaign: action.payload}
  case "SETSELECTEDPHONE":
    return {...state, selectedPhone: action.payload}
  case "SETSELECTEDSUBMISSION":
    return {...state, selectedSubmission: action.payload }
  case "SETFAQ":
    return {...state, faq: action.payload}
  case "SETSUBMITTED":
    return {...state, isSubmitted: action.payload}
  case "SETCHOSEMAILIN":
    return {...state, choseMailIn: action.payload}
  case "SETTHANKYOUTEXT":
    return {...state, thankYouText: action.payload}
  case "COMMONERROR":
    return {...state, commonError: action.payload}
  case "SETLOOKUPVALUES":
    return {...state, lookupValues: action.payload}
  case "RESETUSER":
    return {...state, user: initialState.user}
  case "MISCERROR":
    return {...state, miscError: action.payload}
  case "SETAAAMEMBERID":
    return {...state, aaaMemberId: action.payload}
  case "SETCOSTCOMEMBERID":
    return { ...state, costcoMemberId: action.payload }
  case "SETSAMSMEMBERNUMBER":
    return { ...state, samsMemberNumber: action.payload }
  case "SETLINEVALIDATION":
    return {...state, validateLine: action.payload}
  case "SETPROMOCODE":
    return {...state, promoCode: action.payload}
  case "SETFILTEREDSUBMISSIONSBYMSISDN":
    return {...state, filteredSubmissionsByMsisdn: action.payload}   
  default:
    return state
  }
}

export const AppContext = createContext()

export const AppProvider = props => {
  const [state, dispatch] = useReducer(appReducer, finalInitialState)

  useEffect(() => {
    //reset miscError
    state.miscError = {}

    sessionStorage.setItem(STATE_NAME, JSON.stringify(state))
  }, [state])

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {props.children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.element
}

export const DefaultText = {
  en: {
    BODY_TEXT_FIRST_SECTION: `<p>Lets start your Apple TV+ On Us submission</p> <p>Enter your T-Mobile for Business and select your promotion below based on your rate plan.&nbsp; You will then be prompted to log into your account.</p> <p>T-Mobile postpaid consumer voice customers ready to add Apple TV+,&nbsp;<u><strong><a href="https://www.t-mobile.com/account/benefit-redemption/paramountplus">click here</a></strong></u>&nbsp;to login and add your promotion.</p>`,
    BODY_TEXT_FIRST_SECTION2: `<p><strong>T-Mobile for Business customers, Business Unlimited Ultimate+, or equivalent plan</strong>&nbsp;<strong>can get Apple TV+ On Us!</strong>&nbsp;Initial code provides 12 months of Apple TV+ On Us. Cancel anytime. Subscriber account registration and credit card required. Before your 12-month code expires, if you are still on an eligible rate plan, you will be provided another code or given the option to continue the promotion on your T-Mobile bill. If you do not redeem the offer extension or cancel your subscription you will be billed $4.99/mo. when your 12-month promotional trial ends.&nbsp;</p> <p>&ndash;Unsure if your plan qualifies?&nbsp; Unlimited Premium, Unlimited Plus, Advantage Unlimited Plus, Business Unlimited Ultimate, Magenta Plus Business, and Magenta MAX for Business are also eligible, including Military, First Responder, 55+, and Deaf or Hard of Hearing/Visually Impaired versions of these plans.</p>`
  }
}
