import React, {useEffect, useContext, useState} from "react"
import PropTypes from "prop-types"

import { AppContext,DefaultText } from "../components/context/AppContext"
import { OfferApi } from "../utils/api"

import { MaintenanceModeComponent } from "../components"
import { PandoraGetStartedComponent, PandoraWelcomeComponent } from "../components/Pandora"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

const offerId = Number.parseInt(process.env.REACT_APP_PANDORA_OFFER_ID)

export const Pandora = () => {
  const {dispatch, state} = useContext(AppContext)
  const [inMaintenanceMode, setInMaintenanceMode] = useState(true)
  const [maintenanceText, setMaintenanceText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorFetchingMaintenanceText, setIsErrorFetchingMaintenanceText] = useState(false)
  const [landingPageImageText, setLandingPageImageText] = useState("")
  const [landingPageFirstSection, setLandingPageFirstSection] = useState("")
  const [landingPageSecSection, setLandingPageSecSection] = useState("")
  const [campaignId, setCampaignId] = useState(0)
  

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.PANDORA);
    dispatch({type: "RESETUSER"})
  }, [dispatch])

  useEffect(() => {
    const checkMaintenanceMode = async () => {

      setIsLoading(true)
      try {

        const clientMaintenanceFlagResult = await OfferApi.getClientAsset("MaintenanceFlag")

        const clientMaintenanceText = await OfferApi.getClientAsset("MaintenanceText")

        if (clientMaintenanceFlagResult.notes.toLowerCase() === "true") {
          setMaintenanceText(clientMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        const offerMaintenanceFlag = await OfferApi.getOfferAsset(offerId, "MaintenanceFlag")
        const offerMaintenanceText = await OfferApi.getOfferAsset(offerId, "MaintenanceText")

        if (offerMaintenanceFlag.notes && offerMaintenanceFlag.notes.toLowerCase() === "true") {
          setMaintenanceText(offerMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        setInMaintenanceMode(false)
      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          setIsErrorFetchingMaintenanceText(true)
        }

        setErrorMessage("Something went wrong. Please try again.")
      } finally {
        setIsLoading(false)
      }
    }

    const getLandingPageText = async () => {
      setIsLoading(true)
      try {
        const resOfferCampaignDetails = await OfferApi.getOfferCampaignDetails(offerId)
        const mainImageText = await OfferApi.getOfferAsset(offerId, "IMAGE_TEXT_LANDING_PAGE")
        const firstSectionText = await OfferApi.getOfferAsset(offerId, "BODY_TEXT_LANDING_PAGE")
        const secondSectionText = await OfferApi.getOfferAsset(offerId, "BODY_TEXT_LANDING_PAGE2")

        setCampaignId(resOfferCampaignDetails.data.campaignId)
        mainImageText.length === 0 ? setLandingPageImageText(DefaultText.en.LAND_PAGE_BACKGRND_IMG) : setLandingPageImageText(mainImageText.notes)
        firstSectionText.length === 0 ? setLandingPageFirstSection(DefaultText.en.BODY_TEXT_FIRST_SECTION) : setLandingPageFirstSection(firstSectionText.notes)
        secondSectionText.length === 0 ? setLandingPageSecSection(DefaultText.en.BODY_TEXT_FIRST_SECTION2) : setLandingPageSecSection(secondSectionText.notes)

        setIsLoading(false)

      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          console.log(error)
        }
      } finally {
        setIsLoading(false)
      }
    }

    checkMaintenanceMode()

    getLandingPageText()

  }, [inMaintenanceMode, landingPageFirstSection])
  const ActiveContent = () => (
    <>
      <PandoraGetStartedComponent errorMessage={state.commonError} offerId={offerId} firstSectionText={landingPageFirstSection} secondSectionText={landingPageSecSection} setErrorMessage={setErrorMessage} />
    </>)

  return (
    <>
    <PandoraWelcomeComponent  backgrndImgText={landingPageImageText} offerCampaignId={campaignId}/>
    {isErrorFetchingMaintenanceText &&
    <div className="text-center container">
      <h1 className="magenta">Temporarily Unavailable</h1>
      <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
      <p> Thank you for your patience.</p>
    </div> }
    {isLoading && <div style={{textAlign: "center"}}>Loading ...</div>}
    {errorMessage.length > 0 && !isLoading && !isErrorFetchingMaintenanceText && <div style={{textAlign: "center"}}>{errorMessage}</div>}
    {inMaintenanceMode && !isLoading && <MaintenanceModeComponent maintenanceText={maintenanceText} />}
    {!inMaintenanceMode && !isLoading && !isErrorFetchingMaintenanceText && <ActiveContent /> }
    </>
  )
}

Pandora.propTypes = {
  history: PropTypes.object
}
