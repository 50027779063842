export default {
  TVISION_OFFERID: Number.parseInt(process.env.REACT_APP_TVISION_OFFER_ID),
  APPLETV_REBATE_OFFERID: Number.parseInt(process.env.REACT_APP_APPLETV_REBATE_OFFERID_ID),
  APPLE_FITNESS_P2: Number.parseInt(process.env.REACT_APP_APPLEFITNESSP2_OFFER_ID),
  APPLE_FITNESS_P3: Number.parseInt(process.env.REACT_APP_APPLEFITNESSP3_OFFER_ID),
  APPLE_TV_P1: Number.parseInt(process.env.REACT_APP_APPLETVP1_OFFER_ID),
  APPLE_TV_P3: Number.parseInt(process.env.REACT_APP_APPLETVP3_OFFER_ID),
  APPLE_TV_P4: Number.parseInt(process.env.REACT_APP_APPLETVP4_OFFER_ID),
  YT_BG_A: Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPA_OFFER_ID),
  YT_BG_B: Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPB_OFFER_ID),
  YT_BG_C: Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPC_OFFER_ID),
  PHILO_OFFERID: Number.parseInt(process.env.REACT_APP_PHILO_OFFER_ID),
  PARAMOUNT_OFFERID: Number.parseInt(process.env.REACT_APP_PARAMOUNT_OFFER_ID),
  AAA_OFFERID: Number.parseInt(process.env.REACT_APP_AAA_OFFER_ID),
  OOMA_OFFERID: Number.parseInt(process.env.REACT_APP_OOMA_OFFER_ID),
  UNIVISION_OFFERID: Number.parseInt(process.env.REACT_APP_UNIVISION_OFFER_ID),
  TIDAL_OFFERID: Number.parseInt(process.env.REACT_APP_TIDAL_OFFER_ID),
  SIRIUS_OFFERID: Number.parseInt(process.env.REACT_APP_SIRIUS_OFFER_ID),
  GOOGLEHINT_OFFERID: Number.parseInt(process.env.REACT_APP_GOOGLE_OFFER_ID),
  SIRIUSXM_OFFERID: Number.parseInt(process.env.REACT_APP_SIRIUSXM_OFFER_ID),
  PANDORA_OFFERID: Number.parseInt(process.env.REACT_APP_PANDORA_OFFER_ID),
  R3DSTAPLER_OFFERID: Number.parseInt(process.env.REACT_APP_R3DSTAPLER_OFFER_ID),
  SIXMONTHS_OFFERID: Number.parseInt(process.env.REACT_APP_SIXMONTHS_OFFER_ID),
  GO5GNEXT_OFFERID: Number.parseInt(process.env.REACT_APP_GO5GNEXT_OFFER_ID)
}
