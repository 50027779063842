import styled, { css } from "styled-components"

const chooseImages = ({ offerIs, useThankyouImage, useThankyouImageMob }) => {

  let desktopUrl = "/images/default_thanks_desktop.jpg"
  let mobileUrl = "/images/default_thanks_mobile.jpg"
  if (offerIs.isTVisionOffer) {
    desktopUrl = "/images/TVision/thanks_desktop.jpg"
    mobileUrl = "/images/TVision/thanks_mobile.jpg"
  } else if (offerIs.isPhiloOffer) {
    desktopUrl = "/images/Philo/thanks_desktop.jpg"
    mobileUrl = "/images/Philo/thanks_mobile.jpg"
  } else if (offerIs.isParamountOffer) {
    desktopUrl = "/images/Paramount/thanks_desktop.jpg"
    mobileUrl = "/images/Paramount/thanks_mobile.jpg"
  } else if (useThankyouImage) {
    desktopUrl = "data:image/jpeg;base64," + useThankyouImage
    mobileUrl = "data:image/jpeg;base64," + useThankyouImageMob
  } else if (offerIs.isUniVisionOffer) {
    desktopUrl = "/images/Univision/Univision_ThankYouPage_Desktop.jpg"
    mobileUrl = "/images/Univision/Univision_ThankYouPage_Mobile.jpeg"
  } else if (offerIs.isOomaOffer) {
    desktopUrl = "/images/Ooma/landing_desktop_2.jpg"
    mobileUrl = "/images/Ooma/landing_mobile_2.jpg"
  } else if (offerIs.isAaaOffer) {
    desktopUrl = "/images/AAA/TM_Thanks-1240x500-couple.jpeg"
    mobileUrl = "/images/AAA/TM_Thanks-723x600-couple.jpeg"
  } else if (offerIs.isGoogleHintOffer) {
    desktopUrl = "/images/Google/google-thank.desktop.jpg"
    mobileUrl = "/images/Google/google-thank.mobile.jpg"
  } else if (offerIs.isTidalOffer) {
    desktopUrl =  "/images/Tidal/Thanks_Desktop.png"
    mobileUrl =   "/images/Tidal/Thanks_Mobile.png"
  } else if (offerIs.isR3dStaplerOffer) {
    desktopUrl =  "/images/R3dStapler/TMobile_R3dstapler_Desktop.jpg"
    mobileUrl =  "/images/R3dStapler/TMobile_R3dstapler_Mobile.jpg"
  } else if(offerIs.isSixMonthsOffer) {
    desktopUrl = "/images/SixMonths/SixMonths_ThankYouPage_Desktop.jpg"
    mobileUrl = "/images/SixMonths/SixMonths_ThankYouPage_Mobile.jpg"
  }
  else if(offerIs.isGO5GNEXTOffer) {
    desktopUrl = "/images/GO5GNEXT/GO5GNEXT_Logo.png"
    mobileUrl = "/images/GO5GNEXT/GO5GNEXT_Logo.png"
  }
  return { desktopUrl, mobileUrl }
}
export const ThankYouSection = styled.section`
  ${(props) => {
    const { desktopUrl, mobileUrl } = chooseImages(props)
   if(props.offerIs.isR3dStaplerOffer)
   {
    return css`
    background-color: black;
    background-image: url(${mobileUrl});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 48vh;
    @media (min-width: 768px) {
      background-image: url(${desktopUrl});
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 140vh;
    }
    @media only screen 
    and (device-width : 768px) 
    and (device-height: 1024px) { 
      background-color: black;
      background-image: url(${desktopUrl});
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 140vh;
    }
    @media only screen 
    and (device-width : 820px) 
    and (device-height: 1180px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 73vh;
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height: 667px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 60vh;
    }
    @media only screen 
    and (device-width : 360px) 
    and (device-height: 740px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 52vh;
    }
    @media only screen 
    and (device-width : 540px) 
    and (device-height: 720px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 78vh;
    }
    @media only screen 
    and (device-width : 1024px) 
    and (device-height: 1366px)  { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 77vh;
    }
    @media only screen 
    and (device-width : 912px) 
    and (device-height: 1368px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 71vh;
    }
    @media only screen 
    and (device-width : 1024px) 
    and (device-height: 1366px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 70vh;
    }
    @media only screen 
    and (device-width : 280px) 
    and (device-height: 653px) { 
      background-color: black;
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 46vh;
    }
 
  `
   }
   else {
    return css`
      background-image: url(${mobileUrl});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      @media (min-width: 768px) {
        background-image: url(${desktopUrl});
      }
    `
  }}}
  `
