import React, { useState } from "react";

import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck";
import { Button } from "../styled/Button";

const UNIVISION_PROMO_CODE = "2022ViXP1";

export const UnivisionGetStartedComponent = () => {
  const [submissionPromoCode, setSubmissionPromoCode] =
    useState(UNIVISION_PROMO_CODE);

  const initialValues = {
    phoneNumber: "",
  };

  const getButton =
    (promoCode) =>
    ({ disabled, handleClick }) => {
      return (
        <>
          <Button
            type="button"
            className="btn"
            primary
            disabled={disabled}
            onClick={() => {
              setSubmissionPromoCode(promoCode);
              handleClick();
            }}
          >
            Continue
          </Button>
        </>
      );
    };

  const submitButtons = [getButton(UNIVISION_PROMO_CODE)];

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Let's start your submission</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <h5 className="magenta text-center">Offer Ends 08/11/24</h5>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <img
              style={{ marginTop: "1rem" }}
              src={"images/Univision/Univision_logo.png"}
              alt="Univision Logo"
              className="mb-3"
            />
            <p>
              Get One Year of ViX Premium ON US! ViX Premium is the new
              Spanish-language streaming service with more than 10,000 hours of
              original series, exclusive movie premieres, premium and live
              sports, plus news and entertainment, live or on-demand.
              <br />
              <br />
              Already have a ViX Premium subscription?{" "}
              <a href="https://www.t-mobile.com/tv-streaming/vix-premium-deal#faq">
                Click here
              </a>{" "}
              before you redeem your offer.
            </p>
          </div>
        </div>
        <div className="mx-auto col">
          <CrossCompanyPhoneCheck
            initialValues={initialValues}
            buttons={submitButtons}
            promoCode={submissionPromoCode}
            phoneInputClass="col-md-4 mx-auto"
          />
        </div>
        <div className="mx-auto col-md-5 text-center">
          <p>
            Questions before you continue? To find answers{" "}
            <a
              href="https://www.t-mobile.com/tv-streaming/vix-premium-deal#faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};
