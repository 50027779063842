import React, { useState, useEffect } from "react"
import { CarouselComponent } from "../CarouselComponent"

export const UnivisionWelcomeComponent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 768 ? "/images/Univision/Unvision_LandingPage_Desktop.jpg" : "/images/Univision/Univision_LandingPage_Mobile.jpg";

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);


  return (
    <CarouselComponent height="100vh" bgPosition="top center" images={[imageUrl]}>
        <div className="row">
          <div className="col mx-auto">
            <p className="text-center title-paramount"></p>
          </div>
        </div>
    </CarouselComponent>
  )
}
