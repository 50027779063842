import React, {useEffect, useContext, useState} from "react"
import PropTypes from "prop-types"

import { AppContext } from "../components/context/AppContext"
import { OfferApi } from "../utils/api"

import { MaintenanceModeComponent } from "../components"
import { TidalWelcomeComponent, TidalGetStartedComponent } from "../components/Tidal"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

const offerId = Number.parseInt(process.env.REACT_APP_TIDAL_OFFER_ID)

export const Tidal = () => {
  const {dispatch, state} = useContext(AppContext)
  const [inMaintenanceMode, setInMaintenanceMode] = useState(true)
  const [maintenanceText, setMaintenanceText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorFetchingMaintenanceText, setIsErrorFetchingMaintenanceText] = useState(false)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.TIDAL);
    dispatch({type: "RESETUSER"})
  }, [dispatch])

  useEffect(() => {
    const checkMaintenanceMode = async () => {

      setIsLoading(true)
      try {
        const clientMaintenanceFlagResult = await OfferApi.getClientAsset("MaintenanceFlag")

        const clientMaintenanceText = await OfferApi.getClientAsset("MaintenanceText")

        if (clientMaintenanceFlagResult.notes.toLowerCase() === "true") {
          setMaintenanceText(clientMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        const offerMaintenanceFlag = await OfferApi.getOfferAsset(offerId, "MaintenanceFlag")
        const offerMaintenanceText = await OfferApi.getOfferAsset(offerId, "MaintenanceText")

        if (offerMaintenanceFlag.notes && offerMaintenanceFlag.notes.toLowerCase() === "true") {
          setMaintenanceText(offerMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        setInMaintenanceMode(false)
      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          setIsErrorFetchingMaintenanceText(true)
        }
        console.log(error)
        setErrorMessage("Something went wrong. Please try again.")
      } finally {
        setIsLoading(false)
      }
    }

    checkMaintenanceMode()
  }, [inMaintenanceMode])

  const ActiveContent = () => (
    <>
      <TidalGetStartedComponent errorMessage={state.commonError} offerId={offerId} setErrorMessage={setErrorMessage} />
    </>)

  return (
    <>
    <TidalWelcomeComponent />
    {isErrorFetchingMaintenanceText &&
    <div className="text-center container">
      <h1 className="magenta">Temporarily Unavailable</h1>
      <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
      <p> Thank you for your patience.</p>
    </div> }
    {isLoading && <div style={{textAlign: "center"}}>Loading ...</div>}
    {errorMessage.length > 0 && !isLoading && !isErrorFetchingMaintenanceText && <div style={{textAlign: "center"}}>{errorMessage}</div>}
    {inMaintenanceMode && !isLoading && <MaintenanceModeComponent maintenanceText={maintenanceText} />}
    {!inMaintenanceMode && !isLoading && !isErrorFetchingMaintenanceText && <ActiveContent /> }
    </>
  )
}

Tidal.propTypes = {
  history: PropTypes.object
}
